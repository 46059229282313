import React, {ChangeEvent, useState} from 'react';
import NotifySuccess from "../Components/Notify/NotifySuccess";
import NotifyError from "../Components/Notify/NotifyError";

const Unsubscribe = () => {
    const [email, setEmail] = useState<string>('');
    const [number, setNumber] = useState<string>('');
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const isValid = (number.length === 12 && number[0] === "3" || number.length === 13 && number[0] === "+") || (number.length === 10 && number[0] === "0");
    const handleTelNumber = (e: any) => {
        let numb = e.replace(/\D/g, '').replace(/^7/, '8');
        setNumber(numb);
    };
    const requestUnsubscribeMail = () => {
        if (!re.test(String(email).toLowerCase())) {
            alert("error")
        } else {
            let arr = [];
            let link;
            let index;
            for (let i = 0; i < email.length; i++) {
                arr.push(email[i]);
                index = (arr.findIndex(item => item === "@")) + 1;
                link = arr.slice(index).join("");
            }
            fetch(`https://leads.axioma.marketing/ads/new/37`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "mode": "no-cors",
                    "Access-Control-Allow-Origin": "*"
                },
                body: JSON.stringify({
                    email,
                })
            }).then((res) => {
                    NotifySuccess("Успіх, вас видалено з бази");
                    setEmail("");
                }
            )
        }
    };
    const requestUnsubscribeTel = () => {
        if (!isValid) {
            alert("Неправильний номер телефону");
        } else {
            fetch("https://cstat.nextel.com.ua:8443/tracking/api/lists", {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Authorization": "GcHFq5a207Lm",
                },
                method: "POST",
                body: JSON.stringify({
                    "list": "BLOCKED_PHONES",
                    "operation": "ADD",
                    "items": [`38${number}`]
                }),
            }).then(r => {
                NotifySuccess("Успіх, вас видалено з бази");
                setNumber("");
            }).catch(e => {
                NotifyError("виникла помилка, спробуйте пізніше")
                console.log(e)
            })
        }
    };
    return (
        <div className="unsubscribe__container">
            <div className="unsubscribe__block__container">
                <h2 className="unsubscribe__title">скасування підписки</h2>
                <p className="unsubscribe__subtitle">Введіть свої данні, ми додамо вас до чорного списку і ви
                    більше не будете отримувати новин та промоакцій на свій email та телефон</p>
                <div>
                    <div className={"input__container__unsubscribe"}>
                        <label className={'label__inp__unsubscribe'} htmlFor="email">
                            Пошта
                        </label>
                        <span className={"input__name__icon "}/>
                        <input className={"input__unsubscribe"} id="email"
                               type="text" placeholder='введіть свій email' value={email}
                               onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}/>
                    </div>
                    <button onClick={requestUnsubscribeMail} className="unsubscribe__button">Відмовитись від новин
                    </button>
                </div>
                <div>
                    <div className={"input__container__unsubscribe"}>
                        <label className={'label__inp__unsubscribe'} htmlFor="number">
                            Номер телефону
                        </label>
                        <span className={"input__tel__icon "}/>
                        <input maxLength={10} minLength={8} className={"input__unsubscribe"} id="number"
                               type="number" placeholder='063-000-00-00' value={number}
                               onChange={(e: ChangeEvent<HTMLInputElement>) => handleTelNumber(e.currentTarget.value)}/>
                    </div>
                    <button onClick={requestUnsubscribeTel} className="unsubscribe__button">Відмовитись від новин
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Unsubscribe;