import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowIcon from "./icons/ArrowIcon";
import LockIcon from "./icons/LockIcon";
// import PromoIcon from "./icons/PromoIcon";
import { useDispatch, useSelector } from "react-redux";
import { removePopup } from "../store/reducers/PopupReducer";
import GoogleAuthBtn from "./Auth/GoogleAuthBtn";
import useAuth from "../hooks/useAuth";
import NotifySuccess from "./Notify/NotifySuccess";
import NotifyError from "./Notify/NotifyError";

const Popup = () => {
  const popup = useSelector((state: any) => state.popup);
  const text = useSelector((state: any) => state.text);
  const AllText = useSelector((state: any) => state.allText);
  const dispatch = useDispatch();
  const handlePopupBtnRemove = () => {
    dispatch(removePopup());
  };
  const [checkedI, setChecked] = useState<boolean>(true);
  const [activePopup, setActivePopup] = useState<boolean>(popup);
  useEffect(() => {
    setActivePopup(!popup);
  }, [popup]);
  const { isAuth, user, loginResend } = useAuth();
  const handleLoginResend = () => {
    loginResend()
      .then((r) => {
        NotifySuccess(
          "Die zweite E-Mail wurde verschickt, überprüfen Sie Ihre Mails"
        );
      })
      .catch((e) =>
        NotifyError(
          "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal."
        )
      );
  };
  return (
    <div
      className={!popup ? "popup__container" : "popup__container active"}
      onClick={handlePopupBtnRemove}
    >
      <div
        className={activePopup ? `popup` : "popup active"}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={
            isAuth && !user.emailVerified
              ? "popup__text__container popup__text__container__p__min"
              : "popup__text__container"
          }
        >
          <LockIcon />
          <p className={text === "лайк" ? "popup__text like" : "popup__text"}>
            {/*{text === "email" ? "підтвердіть свій email на пошті щоб " : " Увійдіть або реєструйтеся для отримання "}*/}
            {/*{text === "лайк" ? " отримати можливість ставити лайки " : " "}*/}
            {AllText}
          </p>
          <p>
            {" "}
            {text !== "лайк" && (
              <span className="popup__text__promo">Promo-Codes</span>
            )}
          </p>
          {/*<p className="popup__text__promo">*/}
          {/*{text === "Промокоди" && <span> <PromoIcon/> </span>}*/}
          {/*{text !== "лайк" && "Промокоди"}*/}
          {/*</p>*/}
        </div>
        <div className="popup__link__container">
          {text === "email" ? (
            <>
              <div
                className="popup__link__login"
                onClick={handlePopupBtnRemove}
              >
                verständlicherweise
              </div>
              {isAuth && !user.emailVerified ? (
                <div className="popup__resend__container">
                  <p className="popup__resend__text">
                    Wenn Sie kein Bestätigungsschreiben mit der Post erhalten,
                    können Sie erneut abschicken
                  </p>
                  <button
                    onClick={handleLoginResend}
                    className="popup__resend__btn"
                  >
                    Senden Sie
                  </button>
                </div>
              ) : (
                <>
                  <Link
                    className="popup__link__main"
                    onClick={handlePopupBtnRemove}
                    to="/"
                  >
                    zur Hauptseite
                  </Link>
                </>
              )}
            </>
          ) : (
            <>
              <GoogleAuthBtn
                className="popup__google__auth"
                disabled={!checkedI}
                label="Вхід через Google"
              />
              <div className="popup__checkbox__container">
                <input
                  type="checkbox"
                  checked={checkedI}
                  className={"registration-login__checkbox"}
                  defaultChecked={checkedI}
                  onChange={() => setChecked(!checkedI)}
                />
                <label
                  onClick={() => setChecked(!checkedI)}
                  className={"popup__checkbox__text"}
                >
                  Ich bin damit einverstanden, Werbematerial per E-Mail zu
                  erhalten
                </label>
              </div>
              <p className={"popup__or__email__text"}>Oder per E-Mail </p>

              {/*<Link className="popup__link__login" onClick={handlePopupBtnRemove} to="/login">Вхід <span*/}
              {/*    className="arrow__icon__span"><ArrowIcon fill={"#FFFFFF"}/></span> </Link>*/}

              <Link
                className="popup__link__register"
                onClick={handlePopupBtnRemove}
                to="/login"
              >
                Autorisierung{" "}
                <span className="arrow__icon__span">
                  <ArrowIcon fill={"#60A1EC"} />
                </span>{" "}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;
