import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__sub__container">
        <h3 className="footer__sub__title__number">18+</h3>
        <p className="footer__text__copyright">COPYRIGHT © 2024 CLUB CASINO</p>
      </div>
      <div className="footer__sub__container footer__sub__vidmova">
        <h3 className="footer__sub__title">HAFTUNGSAUSSCHLUSS</h3>
        <p className="footer__text">
          Sie müssen sicherstellen, dass Sie alle Alters- und sonstigen und
          andere gesetzliche Anforderungen erfüllen, bevor Sie das Casino
          betreten oder eine Wette platzieren. Die Informationen auf dieser
          Website sind nur für Casinospieler bestimmt.
        </p>
      </div>
      <div className="footer__sub__container footer__sub__gra ">
        <h3 className="footer__sub__title">VERANTWORTUNGSVOLLES SPIELEN</h3>
        <p className="footer__text">
          Die Club Casino-Website bietet keine Spiele um echtes Geld an und ist
          nicht verantwortlich für für etwaige Verluste, die beim Spielen im
          Casino entstehen. Wir ermutigen Sie immer zur die Verwendung eines
          geeigneten Glücksspiels.
        </p>
      </div>
      {/* <div className="footer__sub__container footer__unsubscribe__link__container">
        <NavLink exact to="/unsubscribe" className="footer__unsubscribe__link">
          Відмовитись від розсилань
        </NavLink>
      </div> */}
    </footer>
  );
};

export default Footer;
