import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import "./styles/index.scss";
import AppRoutes from "./Components/AppRoutes";
import GlobalContextProvider from "./context/GlobalContext";
import ScrollToTop from "./Components/ScrollToTop";
import {createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import {popupReducer} from "./store/reducers/PopupReducer";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const store: any = createStore(popupReducer, applyMiddleware());

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <GlobalContextProvider>
                <Provider store={store}>
                    <ScrollToTop/>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />

                    <AppRoutes/>
                </Provider>
            </GlobalContextProvider>
        </BrowserRouter>
    </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

